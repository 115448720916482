.titleBlock{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
}
.subtitle{
    color: #5C5C5C;
}
.section{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
}
.itemBox{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 24px 20px;
    background-color: #fff;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    border: 1px solid rgba(201, 201, 201, 0.6);
}
.itemBoxDisabled{
    opacity: 0.5;
}
.itemBoxActive:after,
.itemBoxInActive:after{
    content: '';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.itemBoxActive:after{
    background-color: limegreen;
}
.itemBoxInActive:after{
    background-color: darkred;
}
.itemStatus{
    position: absolute;
    top: 8px;
    right: 12px;
    color: limegreen;
}
.itemLogo{
    max-height: 100px;
}
.tabContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 10px;
}
.stepTitle{
    margin-bottom: 20px;
}
.testConnection{
    display: flex;
    align-items: center;
    gap: 24px;
}
.green{
    color: #00C46F;
}
.red{
    color: red;
}
.itemInner{
    display: grid;
    align-items: center;
    gap: 16px;
    padding: 20px;
}
.itemInner {
    width: 100%;
    grid-template-columns: minmax(160px, 1fr) minmax(120px, 1fr) minmax(110px, 1fr);
}
.sectionItem{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.valueColor{
    color: #8A8C8F;
}

@media all and (min-width: 768px) {
    .sectionItem{
        grid-template-columns: 1fr 2fr;
        gap: 16px;
    }
}

/*QB STYLE*/
.overview{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.list {
    margin: 0;
    padding-left: 17px;
}
.list li::marker {
    color: #145FF5;
}
.table,
.tableTop{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.tableStatus{}

@media all and (min-width: 1024px) {
    .tableTop{
        flex-direction: row;
        align-items: center;
    }
}
