.titleBlock{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
}
.subtitle{
    color: #5C5C5C;
}

.info{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
}
.infoCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(201,201,201,0.6);
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
}
.infoCardHead{
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.infoCardLinks{
    display: flex;
    gap: 12px;
}
.infoCardBody{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.infoCardBodyText{
    display: flex;
    align-items: baseline;
}
.switch{
    display: flex;
    gap: 10px;
    margin: 16px 0;
}
.pricingTable{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
}
