.inventoryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inputsContainer {
  min-width: 560px;
}
.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 32px;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
