.container {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 15px;
}
.h2,
.h2Underlined {
  font-weight: 600;
  font-size: 44px;
  line-height: 54px;
  z-index: 999;
}
.h2Underlined:after,
.h2UnderlinedPink:after {
  content: '';
  display: block;
  width: 108px;
  height: 3px;
  margin-top: 20px;
}
.h2Underlined:after {
  background-color: #45babc;
}
.h2UnderlinedPink:after {
  background-color: #c85d9f;
}
.h3 {
  font-size: 42px;
  line-height: 54px;
  font-weight: 400;
}
.h4 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
.p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b505b;
}
.title {
  width: 100%;
  /* max-width: 477px; */
  margin: 0 auto;
  text-align: center;
}
.imageContainer {
  border-radius: 50px;
  background: #eceff3;
  width: 576px;
  height: 380px;
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 0;
  font-size: 18px;
  line-height: 28px;
  z-index: 999;
}
.header a {
  color: #fff;
}
.headerInner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.mobileMenu {
  display: none;
}
input[name='showMobileMenu'] {
  display: none;
}
.headerMenu {
  display: flex;
  gap: 50px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.headerActions {
  display: flex;
  align-items: center;
  gap: 30px;
}
.loginButton {
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8px 14px;
  background: transparent;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
}
.registerButton {
  border: none;
  border-radius: 8px;
  padding: 8px 14px;
  background: #fff;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  color: #145ff5;
}
.registerButton:hover {
  border: none;
}

.hero {
  /* margin-top: 100px; */
  padding: 172px 0 128px 0;
  /*height: 100vh;*/
  display: flex;
  align-items: center;
  color: #ffffff;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    #fafafa 0%,
    rgba(59, 124, 255, 1) 50%,
    rgba(104, 157, 255, 1) 100%
  );
}
.heroInner{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
}
.heroInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
}
.heroTitle {
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
}
.heroDescription {
  margin-top: 16px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(255,255,255,0.8);
}
.heroVideo{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gradientButton {
  background: rgb(49, 52, 57);
  background: linear-gradient(
    90deg,
    rgba(49, 52, 57, 1) 0%,
    rgba(59, 124, 255, 1) 40%,
    rgba(55, 120, 255, 1) 100%
  );
  border: none;
  border-radius: 10px;
  padding: 20px 40px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-size: 200% 200%;
  display: flex;
  align-items: center;
}
.gradientButton:hover {
  background-position: 50% 100%;
}

.features {
  padding: 80px 0;
  background-color: #fafafa;
}
.featuresItems {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.featuresItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.featuresItem:nth-child(even) {
  flex-direction: row-reverse;
}
.featuresBlock{
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 560px;
}
.featuresTitle{
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
}
.featuresRow{
  display: flex;
  gap: 16px;
}
.featuresCheck{
  width: 28px;
  min-width: 28px;
}
.featuresText{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.featuresHeading{
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
}
.featuresDescription{
  font-size: 18px;
  line-height: 26px;
  color: #565656;
}

.pricing {
  padding: 64px 0;
  background: #fafafa;
  position: relative;
}
.pricingBackground {
  position: absolute;
  z-index: 0;
  top: -25%;
}
.pricingCta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.pricing .pricingCtaBtn {
  min-width: 350px;
}

.pricingTable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 64px;
  position: relative;
  z-index: 10;
}
.pricingCol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 28px 24px;
  border: 1px solid #c9c9c999;
  border-radius: 16px;
  z-index: 999;
}

.pricingTableHeadTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
  min-height: 41px;
}
.rainbowText {
  background: linear-gradient(
    90deg,
    rgba(221, 125, 255, 1) 0%,
    rgba(225, 205, 134, 1) 25%,
    rgba(139, 203, 146, 1) 50%,
    rgba(113, 194, 239, 1) 75%,
    rgba(59, 255, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  border: 1px solid #145ff566;
  padding: 8px 12px;
  border-radius: 10px;
}
.pricingTableHeadSpecial {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
  border: none;
}

.pricingTableHeadTitle {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.pricingTableHeadDescription {
  margin: 12px 0 0 0;
  font-weight: 500;
}
.pricingTableHeadPrice {
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
}
.pricingTableHeadPricePeriod {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #596780;
}
.pricingTableHeadPriceDescription {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
}
.pricingBenefits {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.pricingBenefit {
  display: flex;
  gap: 8px;
  align-items: center;
}
.pricingBenefit img {
  min-width: 24px;
  width: 24px;
}
.activeCol {
  border: 2px solid #145ff5;
  background: #145ff51a;
}
.pricingBox {
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 60px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
}
.pricingBox:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.integration {
  padding: 60px 0;
  background-color: #fafafa;
  text-align: center;
}
.integrationText {
  margin-bottom: 52px;
}
.integrationSubtitle {
  margin-top: 8px;
}
.integrationItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 30px;
}
.integrationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
.cta {
  background: rgb(153, 199, 255);
  background: linear-gradient(0deg, rgba(153, 199, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
  padding: 156px 0px;
  overflow: hidden;
}
.ctaColumns {
  display: flex;
  gap: 32px;
}
.ctaLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.ctaContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 40px;
}
.ctaHead {
  color: #8f9bb7;
  text-transform: uppercase;
}
.ctaInfo {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.ctaRight {
  position: relative;
  width: 50%;
  z-index: 10;
}
.ctaUserPo {
  position: absolute;
  border-radius: 24px;
  overflow: hidden;
  top: 0;
  left: 25%;
  width: 100%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ctaPoOverview {
  position: absolute;
  border-radius: 24px;
  overflow: hidden;
  top: 50%;
  left: 0%;
  width: 100%;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact {
  padding: 64px 0;
}
.contactInner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
  align-items: center;
}
.contactForm {
  margin-top: 40px;
}
.contactFields {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
}

.footer {
  color: #ffffff;
  background-color: #161923;
}
.footer a {
  font-weight: 400;
  color: rgba(255,255,255,0.6);
}
.footer a:hover {
  color: rgba(255,255,255,1);
}
.footer img,
.footer svg {
  display: block;
}
.footerContainer {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.footerInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
.footerCol{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footerCol a{
  font-size: 14px;
}
.footerSocials {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
}
.footerSocials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
}
.copyrights {
  padding-top: 16px;
  font-size: 14px;
  line-height: 18px;
  border-top: 1px solid rgba(255,255,255,0.3);
  display: flex;
  color: rgba(255,255,255,0.6);
  justify-content: space-between;
}
.copyrightsBlock {
  display: flex;
  gap: 32px;
}

@media all and (max-width: 767px) {
  .h2,
  .h2Underlined {
    font-size: 24px;
    line-height: 30px;
  }
  .h2Underlined:after,
  .h2UnderlinedPink:after {
    width: 100px;
    margin-top: 14px;
  }
  .h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
  .p {
    font-size: 16px;
    line-height: 20px;
  }
  .title {
    width: 90%;
  }

  .mobileMenu {
    display: block;
    width: 28px;
    height: 22px;
  }
  .mobileMenu span {
    display: block;
    height: 3px;
    background-color: #45babc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .mobileMenu span:nth-child(1) {
    width: 20px;
  }
  .mobileMenu span:nth-child(2) {
    margin: 6px 0;
  }
  .mobileMenu span:nth-child(3) {
    width: 14px;
  }
  .header {
    padding: 16px 0;
  }
  .headerLogo img {
    display: block;
    width: 90px;
  }
  .headerMenu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 65px;
    left: -100%;
    gap: 24px;
    width: 100%;
    padding: 24px 16px;
    background-color: #fff;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    visibility: hidden;
    z-index: 9;
  }
  input[name='showMobileMenu']:checked + .headerMenu {
    left: 0;
    visibility: visible;
  }
  .headerActions {
    gap: 16px;
    font-size: 14px;
  }
  .headerActions button {
    min-width: 100px;
    height: 30px;
    font-size: 12px;
  }
  .hero {
    margin-top: 65px;
    padding: 0;
    /* color: #000000; */
    /* background: #fafafa; */
  }

  .heroInner{
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .heroInfo {
    padding: 24px 0;
  }
  .heroTitle {
    font-size: 24px;
    line-height: 34px;
  }
  .heroDescription {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 22px;
  }
  .heroAction {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
    background-color: #000000;
  }
  .heroAction > img {
    display: block;
    width: 100%;
  }
  .heroBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 54px;
    height: 54px;
    margin-top: -27px;
    margin-left: -27px;
    z-index: 2;
  }
  .features {
    padding: 40px 0;
  }
  .featuresItems {
    margin-top: 32px;
  }
  .featuresItem:nth-child(even) {
    flex-direction: row;
  }

  .pricing {
    padding: 40px 0;
  }
  .pricingBackground {
    display: none;
  }
  .pricing .pricingCtaBtn {
    min-width: auto;
  }
  .pricingCol {
    gap: 0;
  }
  .pricingTable {
    grid-template-columns: 1fr;
    gap: 30px;
    margin-top: 32px;
  }
  .pricingBox {
    min-height: auto;
    padding: 12px 0;
  }
  .pricingTableHeadContent {
    padding: 16px;
  }
  .pricingTableHeadDescription {
    margin: 4px 0 0 0;
  }
  .pricingTableHeadPrice {
    font-size: 36px;
  }
  .pricingTableHeadPriceDescription {
    margin-top: 8px;
  }

  .integration {
    padding: 30px 0 40px 0;
  }
  .integrationText {
    margin-bottom: 30px;
  }
  .integrationSubtitle {
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
  }
  .integrationItems {
    gap: 20px;
  }
  .cta {
    padding: 40px 0;
  }
  .ctaInner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .ctaBtns {
    flex-direction: column;
    gap: 20px;
  }
  .contact {
    padding: 36px 0;
  }
  .contactFields {
    margin-bottom: 24px;
  }
}
