.onboardingContainer {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  width: 100%;
}
.onboardingSidebarContainer {
  border: 1px solid #000;
  position: relative;
}
.onboardingSidebar {
  display: none;
}
.onboardingBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.onboardingBackground img {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.sidebarLogo {
  display: flex;
  justify-content: start;
  margin-bottom: 68px;
  width: 100%;
}
.onboardHeading {
  margin-bottom: 72px;
}
.headingSubText {
  background: linear-gradient(90deg, #313439, #3778ff 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  max-width: 540px;
}
.sidebarSteps {
  display: flex;
  flex-direction: column;
  row-gap: 52px;
}

.onboardFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 32px;
}
.onboardLogout {
  display: flex;
  justify-content: end;
  width: 100%;
}
.onboardForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.formHeading {
  margin-bottom: 32px;
  text-align: center;
}
.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 32px;
}
.formBackButton {
  display: flex;
  justify-content: center;
}
.onboardProgress {
  display: none;
}
.onboardProgress span {
  border-radius: 14px;
  width: 84px;
  height: 6px;
  background: #8eb3ff66;
}
.progressActive {
  background: #145ff5 !important;
}
.newCompany {
  min-height: auto !important;
  grid-template-columns: 1fr !important;
}
.newCompany > .onboardFormContainer {
  padding: 0px !important;
}

.locationOptions {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #e2e3e4;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 56px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.locationOption {
  border: none;
  min-height: 52px;
  background: white;
  display: flex;
  justify-content: center;
  padding: 8px 12px;
  flex-direction: column;
}
.locationOptions button:hover {
  background: #deebff;
  cursor: pointer;
}
.noOptions {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media all and (min-width: 1024px) {
  .onboardingContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 100vh;
  }
  .onboardingSidebar {
    background: url(../../public/assets/img/login_register/onboarding.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 120px;
    display: flex;
    flex-direction: column;
    z-index: 20;
  }
  .onboardProgress {
    margin-top: 20px;
    display: flex;
    gap: 16px;
  }
  .onboardForm {
    width: 480px;
  }
}
