.statusContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: end;
}
.approvedRequisitionTitle {
  display: flex;
  flex-direction: column;
}
.formInner {
  margin: 16px -16px;
  padding: 16px;
}
.vendorForm {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #d9d9d9;
}

.requisitionItem {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.requisitionValue {
  color: #8a8c8f;
}
.budgetContainer {
  max-width: 240px;
}
.items {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
}
.itemsTable {
  max-width: 100%;
  color: #000000;
  margin-bottom: 32px;
}
.itemsTableHead,
.itemsInvoiceTableHead {
  display: none;
  /*width: max-content;*/
}
.invoiceTopItem {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
}
.invoiceTopItem p:last-child {
  color: #8a8c8f;
}
.itemsTableBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: max-content;
}
.itemsAdd {
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 20px 0;
}
.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  width: 100%;
}
.itemInner,
.itemInvoiceInner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.item label {
  color: #acaec9;
}

.itemInvoiceNote {
  padding: 0 16px 16px 16px;
}
.itemNote {
  padding: 16px;
}
.itemActions {
  position: absolute;
  top: 8px;
  right: 16px;
  display: flex;
  gap: 16px;
}
.actionButton {
  background-color: #ecf8f8;
}
.attachmentsBlock {
  margin-top: 40px;
}
.attachmentsBlockItem {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.repeat {
  margin-top: 40px;
}
.repeatFields {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
  gap: 40px;
  margin-top: 40px;
}
.repeatTwoInputs {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 20px;
}
.modal {
  padding: 40px;
}
.tooltipCol {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
}
.tooltipRow {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.noOptionRedirect {
  display: flex;
  justify-content: center;
  padding: 8px;
}

.history {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.historyItem {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.historyEvent {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.historyEvent span {
  color: #8a8c8f;
}
.historyDate {
  color: #0a1217cc;
}
.receivingTable {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.itemsContainer {
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  overflow-x: auto;
}
.itemsReceiving {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr) 120px;
  align-items: center;
  gap: 16px;
  padding: 20px;
  border-bottom: 1px solid #d9d9d9;
}

.itemsReceiving:first-child {
  border-bottom: none;
  background: #145ff51a;
}
.itemsReceiving:last-child {
  border-bottom: none;
}
.createButton {
  display: flex;
  justify-content: end;
}
.requestNotes {
  width: 100%;
}
.commentsSection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.comment {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}
.commentTop {
  display: flex;
  justify-content: space-between;
}
.commentTitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.commentTitle span {
  color: #8a8c8f;
}
.commentsButtons {
  display: flex;
  gap: 16px;
}
.commentText {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.summaryWrapper {
  display: flex;
  gap: 16px;
  justify-content: end;
}

@media all and (min-width: 768px) {
  .formInner {
    margin: 40px 0;
    padding: 40px;
    overflow: initial !important;
  }
  .items {
    margin: 40px 0;
  }
  .itemsTable {
    border: 1px solid #d9d9d9;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow-x: auto;
  }
  .itemsTableHead,
  .itemsInvoiceTableHead {
    display: grid;
    gap: 16px;
    padding: 16px 20px;
    color: #0a1217;
    background: #145ff51a;
  }
  .invoiceTopItem {
    grid-template-columns: 1fr 1fr;
  }
  .itemsTableBody {
    display: block;
    width: 100%;
  }
  .item {
    border: none;
    border-top: 1px solid #d9d9d9;
  }
  .itemInner,
  .itemInvoiceInner {
    display: grid;
    align-items: center;
    gap: 16px;
    padding: 20px;
  }
  .itemsTableHead,
  .itemInner {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 120px;
  }
  .itemsTableHeadReview,
  .itemInnerReview {
    grid-template-columns: 32px 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 120px;
  }
  .itemInvoiceInner,
  .itemsInvoiceTableHead {
    grid-template-columns:
      minmax(160px, 4fr) minmax(120px, 2fr) minmax(145px, 2fr) minmax(110px, 1fr) minmax(110px, 1fr)
      minmax(110px, 1fr) minmax(170px, 1fr) minmax(100px, 1fr) 80px;
    gap: 16px;
  }
  .itemInvoiceInner.forVendor,
  .itemsInvoiceTableHead.forVendor {
    grid-template-columns:
      minmax(160px, 4fr) minmax(120px, 2fr) minmax(110px, 1fr) minmax(110px, 1fr)
      minmax(110px, 1fr) minmax(170px, 1fr) minmax(100px, 1fr) 80px;
    gap: 16px;
  }
  .itemInvoiceInner.forIntegration,
  .itemsInvoiceTableHead.forIntegration {
    grid-template-columns: minmax(160px, 1fr) minmax(120px, 1fr) minmax(110px, 1fr);
    gap: 16px;
  }
  .label {
    display: none !important;
  }
  .itemActions {
    position: static;
    justify-content: flex-end;
  }
  .itemNote,
  .itemInvoiceNote {
    margin-right: 130px;
    padding: 0 20px 20px 20px;
  }
  .itemInvoiceNote {
    padding: 0 20px 20px 20px;
  }
  .itemNote {
    padding: 20px;
  }
  .itemsAdd {
    flex-direction: row;
  }
}
